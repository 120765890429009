import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getHomePageData = createAsyncThunk(
    "homePage/getHomePageData",
    async () => {
        const { data } = await axios.get("https://www.api.lalibela.be/api/base/homePageData/")
        return data;
    }
)

const initialState = {
    isLoading: false,
    isError: false,
    error: "",
    success: false,
    homePageData: [],
};

const homePageDataSlice = createSlice({
    name: "homePage",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getHomePageData.pending, (state) => {
            state.isLoading = true
        })

        builder.addCase(getHomePageData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.homePageData = action.payload;
            state.success = true;
        })

        builder.addCase(getHomePageData.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
            state.error = "Request failed with status code 404!"
        })
    }
})

export default homePageDataSlice.reducer;