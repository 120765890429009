import { ListMenuItem } from "../../components";

export const MenuItems = ({ items }) => {
    return (
        <div className="grid lg:grid-cols-2 max-lg:w-[750px] max-md:w-full justify-center items-center max-w-[1500px] mx-auto gap-8">
            {
                items.map((item, index) => {
                    return (<ListMenuItem key={index} item={item} />)
                })
            }
        </div>
    )
}