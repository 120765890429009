import { useState, useRef, useEffect } from "react"

export const MenuCategoryDropDown = ({ categories, activeCategory, filterItems }) => {
    const [showMainDropdown, setShowMainDropdown] = useState(false)

    const dropdownRef = useRef(null);

    const updateFilter = (category) => {
        filterItems(category);
        setShowMainDropdown(false);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowMainDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
    });

    return (
        <>
            <button onClick={() => { setShowMainDropdown(!showMainDropdown) }} id="multiLevelDropdownButton" data-dropdown-toggle="multi-dropdown" className="text-white text-xl bg-buttonPrimary/90 hover:bg-buttonSecondary font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center w-full justify-center" type="button">{activeCategory}<svg className="w-3 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
            </svg>
            </button>
            <div ref={dropdownRef} id="multi-dropdown" className={`me-3 mb-3 md:mb-0 z-10 bg-white ${showMainDropdown ? "" : "hidden"} rounded-lg sm:w-96 max-sm:w-52 shadow-2xl w-36 ml-4 border-2 border-secondary`}>
                <ul className="py-2 text-sm text-gray-700 " aria-labelledby="multiLevelDropdownButton">
                    {
                        categories.map((category, index) => {
                            return (
                                <li key={index} >
                                    <button onClick={() => { updateFilter(category) }} className={` ${activeCategory === category ? "bg-buttonPrimary text-white" : ""} block text-xl px-4 w-full text-gray text-left py-2 hover:bg-buttonPrimary hover:text-white`}>{category}</button>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )
}