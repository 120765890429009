import { IconTextSubtext, EmailForm } from "../components";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import { Helmet } from "react-helmet-async";

export const Contact = () => {
  const details = [
    {
      icon: <FaMapLocationDot size="35px" />,
      infoTitle: "Location:",
      infoSubtitle: "Parijsstraat 22, 3000 Leuven",
      subSubtitle: "",
    },
    {
      icon: <MdEmail size="35px" />,
      infoTitle: "Email:",
      infoSubtitle: "info@lalibela.be",
      subSubtitle: "",
    },
    {
      icon: <IoCallSharp size="35px" />,
      infoTitle: "Call:",
      infoSubtitle: "016 60 19 57",
      subSubtitle: "",
    },
  ];

  return (
    <main>
      <Helmet>
        <title>Lalibela Leuven - Contact</title>
        <meta
          name="keywords"
          content="Ethiopian cuisine, Ethiopian restaurant, Leuven, Authentic Ethiopian food, Ethiopian dishes, Cultural dining experience, Traditional Ethiopian recipes, Vegan, Vegetarian options, Ethiopian spices, Ethiopian coffee ceremony, Parijsstraat 22, 3000 Leuve"
        />
        <meta
          name="description"
          content="Connect with Lalibela Restaurant in Leuven. Visit us at Parijsstraat 22, 3000 Leuven, or contact us at info@lalibela.be | 016 60 19 57. Leuven ethiopian restaurant."
        />
        <link rel="canonical" href="https://www.lalibela.be/contact" />
      </Helmet>

      <div className="w-full flex flex-col justify-center">
        {
          <section>
            <div>
              <iframe
                src={
                  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10068.254124857867!2d4.6807862!3d50.8855345!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c161aa065cccdd%3A0x62d5da4a37c708c2!2sLalibela%20Restaurant%20Leuven!5e0!3m2!1sen!2sbe!4v1716218209749!5m2!1sen!2sbe"
                }
                title="Google Map Location"
                width="100%"
                height="550"
              >
                allowfullscreen="" loading="eager" referrerpolicy=
                {"no-referrer-when-downgrade"}
              </iframe>
            </div>
          </section>
        }

        <section>
          <div className="flex flex-col max-lg:flex-col justify-center max-lg:items-center max-lg:gap-0 gap-10 px-4">
            <div>
              <EmailForm />
            </div>

            <div className="flex flex-col justify-center items-center">
              <IconTextSubtext details={details} />
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};