import { Link, NavLink } from "react-router-dom"
import LogoSvg from "../assets/images/logo/LalibelaLogo.svg"
import { useState } from "react"

export const Header = () => {
    const activeNavButtonStyle = "text-xl text-secondary py-2 px-3 md:p-0 rounded md:bg-transparent text-primary select-none"
    const inactiveNavButtonStyle = "text-lg animate-bounce py-2 px-3 md:p-0 text-gray-900 rounded md:hover:bg-transparent md:border-0 hover:text-secondary select-none"

    const [hideNav, setHideNav] = useState(true)

    return (
        <header className="bg-headerColor z-50">

            <div className="max-w-[1701px] flex flex-wrap items-center justify-between mx-auto px-4">
                <div className="h-32 w-44 mb-4">
                    <Link to="/" onClick={() => {setHideNav(true)}}> <img src={LogoSvg} alt="Lalibela Logo" /></Link>
                </div>

                <button onClick={() => setHideNav(!hideNav)} data-collapse-toggle="navbar-solid-bg" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 " aria-controls="navbar-solid-bg" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>

                <div className={`${hideNav ? "hidden" : ""} w-full md:block md:w-auto mr-1`} id="navbar-solid-bg">
                    <ul className="flex flex-col font-medium mt-4 rounded-lg max-md:items-center md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent">
                        <li>
                            <NavLink to="/" onClick={() => setHideNav(true)} className={({ isActive }) => { return isActive ? activeNavButtonStyle : inactiveNavButtonStyle }} aria-current="page">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" onClick={() => setHideNav(true)} className={({ isActive }) => { return isActive ? activeNavButtonStyle : inactiveNavButtonStyle }}>About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/menu" onClick={() => setHideNav(true)} className={({ isActive }) => { return isActive ? activeNavButtonStyle : inactiveNavButtonStyle }}>Menu</NavLink>
                        </li>
                        <li>
                            <NavLink to="/gallery" onClick={() => setHideNav(true)} className={({ isActive }) => { return isActive ? activeNavButtonStyle : inactiveNavButtonStyle }}>Gallery</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact" onClick={() => setHideNav(true)} className={({ isActive }) => { return isActive ? activeNavButtonStyle : inactiveNavButtonStyle }}>Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}