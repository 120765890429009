import LalibelaSvgLogo from "../assets/images/logo/LalibelaLogo.svg"
import { motion } from 'framer-motion'

export const FixedBackgroundImageWithText = ({ homePageData }) => {
    homePageData = homePageData[0]
    return (
        <div className="bg-fixed bg-no-repeat bg-cover bg-center max-lg:bg-auto"
            style={{ backgroundImage: `url(${LalibelaSvgLogo})` }}>

            <div className='bg-[#876055]/75 w-full h-full pt-4 text-white flex flex-col items-center justify-center gap-2'>
                <span className='text-5xl font-bold max-sm:text-3xl'> {homePageData.title} </span>
                <span className="text-5xl font-bold max-sm:text-3xl"> {homePageData.subTitle} </span>
                {
                    < motion.div
                        className="w-full"
                        initial={{ opacity: 0, y: -50 }}
                        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeInOut' } }}
                        viewport={{ once: false, amount: 0 }}>
                        <div className="p-2 bg-[#876055]">
                            <div className="flex flex-col justify-center items-center">
                                <h2 className="text-3xl font-bold">{homePageData.openingDateTitle}</h2>
                                <span><strong>{homePageData.openingDate}</strong></span>
                                <h3>{homePageData.openingDayTime}</h3>
                                <h3>{homePageData.openingDayDescription}</h3>
                                <h3>{homePageData.openingDaySubDescription}</h3>
                                <hr className="border-gray w-44 my-4" />
                                <h2 className="text-3xl font-bold mb-1">{homePageData.openingHoursTitle}</h2>
                                {
                                    homePageData.openingHours.map((openingHour, index) => {
                                        return (
                                            <span key={index}><strong>{openingHour.day}</strong> : {openingHour.time}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </motion.div>
                }

                <span className="text-justify font-bold mt-4 text-md max-w-2xl pb-6 px-4">
                    {homePageData.description}
                </span>
            </div >
        </div >
    )
}