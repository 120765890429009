import { React, useEffect, useState } from "react"
import { MenuCategory, CarouselWithText, MenuItems, MenuCategoryDropDown } from "../components"
import { Helmet } from 'react-helmet-async'
import { getMenuPageData } from "../reducers/menuPageSlice"
import { useDispatch, useSelector } from "react-redux";
import { PiSpinnerThin } from "react-icons/pi";

export const Menu = () => {
    const images = [
        { id: 0, source: "../assets/food/food3.jpg", title: "fourth" },
    ]

    const { isLoading, isError, error, success, menuPageData } = useSelector(
        (state) => state.menuPage
    )

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMenuPageData());
    }, [dispatch]);

    const [activeCategory, setActiveCategory] = useState('');
    const [menuItems, setMenuItems] = useState([]);
    const [allCategories, setAllCategories] = useState([]);

    useEffect(() => {
        if (menuPageData.length > 0) {
            const menuItems = menuPageData[0]["menuItems"]
            const allCategories = [...new Set(menuItems.map((item) => item.category))]
            setAllCategories(allCategories);
            const initialCategory = allCategories[0];
            setActiveCategory(initialCategory);
            setMenuItems(menuItems.filter((item) => item.category === initialCategory))
        }
    }, [menuPageData])

    const filterItems = (category) => {
        setActiveCategory(category)
        const menuItems = menuPageData[0]["menuItems"]
        const newItems = menuItems.filter((item) => item.category === category)
        setMenuItems(newItems);
    }

    return (
        <main>

            <Helmet>
                <title>Lalibela Leuven - Menu</title>
                <meta name="keywords" content="Ethiopian cuisine, Ethiopian restaurant, Leuven, Authentic Ethiopian food, Ethiopian dishes, Cultural dining experience, Traditional Ethiopian recipes, Vegan, Vegetarian options, Ethiopian spices, Ethiopian coffee ceremony, Parijsstraat 22, 3000 Leuve" />
                <meta name="description" content="Explore the diverse flavors of Ethiopia at Leuven Restaurant. Browse our authentic Ethiopian cuisine menu featuring traditional dishes. Join us for a culinary journey!" />
                <link rel="canonical" href="https://www.lalibela.be/menu" />
            </Helmet>
            <section>
                <CarouselWithText images={images} primaryText="Check out out menu" />
            </section>
            {
                isLoading ? (
                    <div className="flex justify-center items-center">
                        <PiSpinnerThin className="animate-spin" size="200px" />
                    </div>
                ) : isError ? (
                    <div className="flex justify-center flex-col items-center">
                        <p className="text-4xl mt-4">{error}</p>
                        <p className="text-4xl mt-4">Internet could be slow, please refresh the page again</p>
                    </div>
                ) : (
                    <div>
                        {
                            success &&
                            <section>
                                <div className="flex flex-col justify-center mt-6 items-center gap-4">
                                    <p className="text-center text-xl ">{menuPageData[0].foodAllergy}</p>
                                    <p className="text-center text-xl ">{menuPageData[0].mainDishesServedWithEnglish}/<span className="text-[#C5602B]">{menuPageData[0].mainDishesServedWithDutch}</span></p>
                                    <p className="text-center text-xl ">{menuPageData[0].substituteOptionEnglish}/<span className="text-[#C5602B]">{menuPageData[0].substituteOptionDutch}</span></p>
                                </div>
                                <div className="max-sm:hidden border-secondary  border-b-2 h-[80px]">
                                    <MenuCategory
                                        categories={allCategories}
                                        activeCategory={activeCategory}
                                        filterItems={filterItems}
                                    />
                                </div>

                                <div className="flex justify-center items-center sm:hidden border-secondary  border-b-2 h-[80px] select-none">
                                    <MenuCategoryDropDown
                                        categories={allCategories}
                                        activeCategory={activeCategory}
                                        filterItems={filterItems}
                                    />
                                </div>

                            </section>
                        }

                        <section >
                            <div className="flex justify-center gap-14 p-4 max-sm:flex-col">
                                <div className="flex justify-center flex-col items-center gap-2 my-2">
                                    <MenuItems items={menuItems} />
                                </div>
                            </div>
                        </section>
                    </div>
                )
            }
        </main>
    )
}