import { Carousel } from "./Carousel";
import { motion } from "framer-motion";

export const CarouselWithText = ({
  images,
  primaryText,
  secondaryText,
  showNavigationButton,
  showActionButton,
  text,
}) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 z-10 flex justify-center items-center text-white select-none pointer-events-none">
        <motion.div
          className="text-white flex flex-col items-center justify-center"
          initial={{ opacity: 1, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.2, duration: 0.8 },
          }}
          viewport={{ once: true, amount: 0 }}
        >
          <div className="text-center">
            <div>
              <h1 className="font-bold max-sm:text-5xl max-lg:text-6xl lg:text-8xl drop-shadow-[0_15px_10px_rgba(0,0,0,1)] inline-block rounded">
                {primaryText}
              </h1>
            </div>
            <h2 className="font-bold max-sm:text-3xl max-lg:text-5xl lg:text-8xl drop-shadow-[0_15px_10px_rgba(0,0,0,1)] inline-block rounded">
              {secondaryText}
            </h2>
          </div>
        </motion.div>
      </div>

      <div className="relative">
        <Carousel
          images={images}
          showNavigationButton={showNavigationButton}
          showActionButton={showActionButton}
          text={text}
        />
      </div>
    </div>
  );
};
