import { useState, useEffect, useRef, useCallback } from "react";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from "react-icons/bs";
import { Button } from "./Button";

export const Carousel = ({ images, showNavigationButton, showActionButton, text }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const intervalIdRef = useRef(null);

    const startInterval = useCallback(() => {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 6000);
    }, [images.length]);

    useEffect(() => {
        startInterval();
        return () => clearInterval(intervalIdRef.current);
    }, [images.length, intervalIdRef, startInterval]);

    const goToNextImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length)
        startInterval();
    }

    const goToPreviousImage = () => {
        setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length)
        startInterval();
    }

    return (
        <div id="default-carousel" className="relative w-full" data-carousel="slide">
            <div className="relative overflow-hidden max-sm:h-56 max-md:h-96 md:h-100 lg:h-200">
                {
                    images.map((image, index) => {
                        return (
                            <div key={index} className={`${index === currentImageIndex ? "opacity-100" : ""} opacity-0 duration-[5000ms] ease-in-out`} data-carousel-item>
                                <img src={image.source} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" loading="eager" alt="..." />
                            </div>
                        )
                    })
                }
            </div>

            {/* Button at the bottom */}
            <div className={`${showActionButton ? "" : "hidden"} absolute bottom-0 left-0 right-0 flex justify-center pb-8 max-sm:pb-5`}>
                <Button text={text} className="text-gray-900 focus:outline-none font-bold text-3xl max-sm:text-xl max-sm:px-5 bg-buttonPrimary/90 rounded-lg hover:bg-buttonSecondary/90 text-white font-medium rounded-lg px-10 py-3 md:py-5" />
            </div>

            <button onClick={() => goToPreviousImage()} type="button" className={`${showNavigationButton ? "" : "hidden"} absolute top-0 start-0 z-50 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none`} data-carousel-prev>
                <span className="inline-flex items-center justify-center w-10 h-10">
                    <BsArrowLeftSquareFill className="text-primary/75 hover:text-secondary" size="30px" />
                </span>
            </button>

            <button onClick={() => goToNextImage()} type="button" className={`${showNavigationButton ? "" : "hidden"} absolute top-0 end-0 flex z-50 items-center justify-center h-full px-4 cursor-pointer group focus:outline-none`} data-carousel-next>
                <span className="inline-flex items-center justify-center w-10 h-10">
                    <BsArrowRightSquareFill className="text-primary/75 hover:text-secondary" size="30px" />
                </span>
            </button>
        </div >
    )
}