import * as Yup from "yup";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const EmailFormValidation = Yup.object({
    name: Yup.string().min(2).required("name is required"),
    email: Yup.string().matches(emailRegex, 'invalid email format').required('email is required'),
    phoneNumber: "",
    subject: Yup.string().required("subject is required"),
    message: Yup.string().required("message is required")
})