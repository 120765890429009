import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAboutPageData = createAsyncThunk(
    "aboutPage/getAboutPageData",
    async () => {
        const { data } = await axios.get("https://www.api.lalibela.be/api/base/aboutPageData/")
        return data;
    }
)

const initialState = {
    isLoading: false,
    isError: false,
    error: "",
    success: false,
    aboutPageData: [],
};

const aboutPageDataSlice = createSlice({
    name: "aboutPage",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAboutPageData.pending, (state) => {
            state.isLoading = true
        })

        builder.addCase(getAboutPageData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.aboutPageData = action.payload;
            state.success = true;
        })

        builder.addCase(getAboutPageData.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
            state.error = "Request failed with status code 404!";
        })
    }
})

export default aboutPageDataSlice.reducer;