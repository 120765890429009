export const HomePopupContent = ({ setChecked, popupData }) => {
  popupData = popupData[0]
  return (
    <div>
      <div className="flex justify-center items-center">
        <span className="font-bold text-white text-4xl max-sm:text-3xl">Lalibela Restaurant</span>
      </div>
      <hr className="border-gray w-full my-2" />

      <div className="flex flex-col justify-center items-center mb-2">
        <h2 className="text-3xl text-white font-bold">{popupData.openingDateTitle}</h2>
        <span className="text-white text-xl"><strong>{popupData.openingDate}</strong></span>
        <h3 className="text-white text-xl">{popupData.openingDayTime}</h3>
        <h3 className="text-white text-xl max-sm:text-lg">{popupData.openingDayDescription}</h3>
        <h3 className="text-white text-xl max-sm:text-lg">{popupData.openingDaySubDescription}</h3>
        <hr className=" border-gray w-full my-2" />
        <h2 className="text-3xl text-white font-bold mb-1">{popupData.openingHoursTitle}</h2>
        {
          popupData.openingHours.map((openingHour, index) => {
            return (
              <span className="text-white" key={index}><strong>{openingHour.day}</strong> : {openingHour.time}</span>
            )
          })
        }
      </div>

      <hr className="border-gray w-full my-2" />

      <p className="text-center text-white mb-4">Come visit us and enjoy our delicious foods with cozy surrounding</p>

      <div className="flex items-center mb-4 justify-center">
        <input onChange={(event) => setChecked(event.target.checked)} id="link-checkbox" type="checkbox" value="" className="w-4 h-4 bg-gray-100 border-gray-300 rounded" />
        <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium text-white">Do not show this popup again</label>
      </div>
    </div>
  )
}