import { CarouselWithText, FixedBackgroundImageWithText } from "../components"
import { useState, useEffect } from "react"
import { Popup } from "../components/Model/Popup"
import { AnimatePresence } from "framer-motion"
import { HomePopupContent } from "../components/nonGeneric/HomePopupContent"
import { Helmet } from 'react-helmet-async'
import { getHomePageData } from "../reducers/homePageSlice"
import { useDispatch, useSelector } from "react-redux";
import { PiSpinnerThin } from "react-icons/pi";

export const Home = () => {

    const images = [
        { id: 1, source: "../assets/food/food1.png", title: "first" },
        { id: 2, source: "../assets/food/food2.png", title: "second" },
        { id: 3, source: "../assets/food/food4.jpg", title: "third" },
        { id: 2, source: "../assets/food/food5.png", title: "fourth" },
    ]

    const [popupOpen, setPopupOpen] = useState(false);

    // Show the popup only after the screen is loaded
    const [screenLoaded, setScreenLoaded] = useState(false);
    useEffect(() => {
        // Simulate screen loading with a timeout
        const timer = setTimeout(() => {
            setScreenLoaded(true);
        }, 100); // Adjust the timeout duration as needed

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (screenLoaded) {
            setPopupOpen(true);
        }
    }, [screenLoaded]);

    const [showPopupOnlyOnceChecked, setShowPopupOnlyOnceChecked] = useState(false);
    const [showPopupOnlyOnce, setShowPopupOnlyOnce] = useState(localStorage.getItem("showLalibelaPopupOnlyOnce") ? JSON.parse(localStorage.getItem("showLalibelaPopupOnlyOnce")) : false)
    const close = () => {
        setPopupOpen(false);

        setShowPopupOnlyOnce(showPopupOnlyOnceChecked)
        localStorage.setItem("showLalibelaPopupOnlyOnce", JSON.stringify(showPopupOnlyOnceChecked));
    };

    //Show popup once per session
    const [popupShownThisSession, setPopUpShownThisSession] = useState(false)

    useEffect(() => {
        const hasPopUpShownThisSession = sessionStorage.getItem("hasPopupShown");
        if (!hasPopUpShownThisSession) {
            setPopUpShownThisSession(true);
            sessionStorage.setItem("hasPopupShown", true);
        }
    }, [])

    const { isLoading, isError, error, success, homePageData } = useSelector(
        (state) => state.homePage
    )

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHomePageData());
    }, [dispatch]);

    return (
        <div>

            <Helmet>
                <title>Lalibela Leuven - Home</title>
                <meta name="keywords" content="Ethiopian cuisine, Ethiopian restaurant, Leuven, Authentic Ethiopian food, Ethiopian dishes, Cultural dining experience, Traditional Ethiopian recipes, Vegan, Vegetarian options, Ethiopian spices, Ethiopian coffee ceremony, Parijsstraat 22, 3000 Leuve" />
                <meta name="description" content="Lalibela Restaurant Taste of Ethiopia serves best ethiopian food. It's located in Parijsstraat 22, 3000 Leuven." />
                <link rel="canonical" href="https://www.lalibela.be/" />
            </Helmet>

            <section className="relative">
                {
                    <CarouselWithText images={images} primaryText="Experience the Taste of Ethiopia" secondaryText="" showNavigationButton={false} showActionButton={false} text="" />
                }
            </section>

            {isLoading ? (
                <div className="flex justify-center items-center">
                    <PiSpinnerThin className="animate-spin" size="200px" />
                </div>
            ) : isError ? (
                <div className="flex justify-center flex-col items-center">
                    <p className="text-4xl mt-4">{error}</p>
                    <p className="text-4xl mt-4">Internet could be slow, please refresh the page again.</p>
                </div>
            ) :
                (
                    <div>
                        {
                            !showPopupOnlyOnce &&
                            <AnimatePresence
                                initial={false}
                                mode="wait"
                            >
                                {
                                    popupOpen &&
                                    < Popup handleClose={close} displayContent={<HomePopupContent setChecked={setShowPopupOnlyOnceChecked} popupData={homePageData} />} />
                                }
                            </AnimatePresence>
                        }
                        <section>
                            {
                                success &&
                                <FixedBackgroundImageWithText homePageData={homePageData} />
                            }
                        </section>
                    </div>
                )
            }
        </div >
    )
}