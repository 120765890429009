import { CarouselWithText } from "../components";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAboutPageData } from "../reducers/aboutPageSlice";
import { PiSpinnerThin } from "react-icons/pi";

export const About = () => {
  const images = [
    { id: 1, source: "../assets/food/food1.png", title: "first" }
  ];

  const { isLoading, isError, error, success, aboutPageData } = useSelector(
    (state) => state.aboutPage
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAboutPageData());
  }, [dispatch]);

  const secondIndex = "max-xl:flex-col-reverse";
  const otherIndex = "max-xl:flex-col";
  return (
    <main>
      <Helmet>
        <title>Lalibela Leuven - About</title>
        <meta
          name="keywords"
          content="Ethiopian cuisine, Ethiopian restaurant, Leuven, Authentic Ethiopian food, Ethiopian dishes, Cultural dining experience, Traditional Ethiopian recipes, Vegan, Vegetarian options, Ethiopian spices, Ethiopian coffee ceremony, Parijsstraat 22, 3000 Leuve"
        />
        <meta
          name="description"
          content="Our head cook is dedicated to bringing traditional Ethiopian cooking. Lalibela restaurant has plenty of vegan, vegetarian, and gluten-free options. Leuven Lalibela."
        />
        <link rel="canonical" href="https://www.lalibela.be/about" />
      </Helmet>

      <section>
        <CarouselWithText images={images} primaryText="About us" showNavigationButton={false} showActionButton={false} text="" />
      </section>

      {isLoading ? (
        <div className="flex justify-center items-center">
          <PiSpinnerThin className="animate-spin" size="200px" />
        </div>
      ) : isError ? (
        <div className="flex justify-center flex-col items-center">
          <p className="text-4xl mt-4">{error}</p>
          <p className="text-4xl mt-4">Internet could be slow, please refresh the page again</p>
        </div>
      ) : (
        success &&
        aboutPageData[0].aboutPageDetails.map((data, index) => {
          return (
            <section key={index} className="py-4">
              <div
                className={`flex justify-center ${index === 1 ? otherIndex : secondIndex} px-4 max-2xl:items-center sm:gap-8`}
              >
                {
                  index % 2 === 1 &&
                  <div className="max-w-xl mr-5 max-2xl:max-w-xl max-2xl:mr-0">
                    <img
                      className="rounded"
                      src={`https://www.api.lalibela.be${data.image}`}
                      alt="Chef with food"
                    />
                  </div>
                }

                <div className="max-w-4xl flex flex-col justify-center">
                  <h2 className="font-bold text-4xl text-center">
                    {data.title}
                  </h2>
                  <span className="text-justify p-4">{data.description}</span>
                </div>

                {index % 2 === 0 && (
                  <div className="max-w-xl mr-5 max-2xl:max-w-xl max-2xl:mr-0">
                    <img
                      className="rounded"
                      src={`https://www.api.lalibela.be${data.image}`}
                      alt="Chef with food"
                    />
                  </div>
                )}
              </div>
              {aboutPageData.length - 1 !== index && <hr className="mt-4" />}
            </section>
          );
        })
      )}
    </main>
  );
};
