
export const IconTextSubtext = ({ details }) => {

    const isLinkable = (title, subTitle) => {
        if (title.includes("Email")) {
            return <a className="underline" href={`mailto:${subTitle}`}>{subTitle}</a>
        }
        else if (title.includes("Location")) {
            return (
                <a href="https://maps.app.goo.gl/vRdf1tsoawvYxbWc8" className="underline text-primary hover:cursor-pointer" target="_blank" rel="noreferrer">{subTitle}</a>
            )
        }
        return <span>{subTitle}</span>
    }

    return (
        <div className="flex flex-col gap-11 py-10 justify-center">
            {
                details.map((detail, index) => {
                    return (
                        <div key={index} className="flex flex-row items-center gap-2">
                            <div>
                                {detail.icon}
                            </div>
                            <div>
                                {detail.infoTitle}
                                <p></p>
                                {
                                    isLinkable(detail.infoTitle, detail.infoSubtitle)
                                }
                                <p></p>
                                {detail.subSubtitle}
                            </div>
                        </div>
                    )
                })
            }
        </div >
    )
}