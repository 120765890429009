import React from "react"

export const MenuCategory = ({ categories, activeCategory, filterItems }) => {

    return (
        <div className="flex gap-10 items-center justify-center h-20 select-none">
            {
                categories.map((category, index) => {
                    return (
                        <span onClick={() => { filterItems(category) }} key={index} className={`font-bold hover:cursor-pointer text-primary hover:text-secondary ${category === activeCategory ? "text-secondary" : "text-black"}`}>{category}</span>
                    )
                })
            }
        </div>
    )
}