import { configureStore } from '@reduxjs/toolkit'
import aboutPageReducer from './reducers/aboutPageSlice'
import homePageReducer from './reducers/homePageSlice'
import menuPageReducer from './reducers/menuPageSlice'
import galleryPageReducer from "./reducers/galleryPageSlice"

export const store = configureStore({
    reducer: {
        aboutPage: aboutPageReducer,
        homePage: homePageReducer,
        menuPage: menuPageReducer,
        galleryPage: galleryPageReducer,
    }
})