import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getGalleryPageData = createAsyncThunk(
    "galleryPage/getGalleryPageData",
    async () => {
        const { data } = await axios.get("https://www.api.lalibela.be/api/base/galleryPageData/")
        return data;
    }
)

const initialState = {
    isLoading: false,
    isError: false,
    error: "",
    success: false,
    galleryPageData: [],
};

const galleryPageDataSlice = createSlice({
    name: "galleryPage",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGalleryPageData.pending, (state) => {
            state.isLoading = true
        })

        builder.addCase(getGalleryPageData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.galleryPageData = action.payload;
            state.success = true;
        })

        builder.addCase(getGalleryPageData.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
            state.error = "Request failed with status code 404!";
        })
    }
})

export default galleryPageDataSlice.reducer