import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaFacebook } from "react-icons/fa";
import { BiLogoTripAdvisor } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { TfiEmail } from "react-icons/tfi";
import { IoCallSharp } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { PiSpinnerThin } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { getHomePageData } from "../reducers/homePageSlice"

export const Footer = () => {
  const [isFbIconHovered, setIsFbIconHovered] = useState(false);
  const [isLTripAdvisorIconHovered, setIsTripAdvirosIconHovered] =
    useState(false);
  const [isInstagramIconHovered, setIsInstagramIconHovered] = useState(false);

  const googleMapAddress = "https://maps.app.goo.gl/vRdf1tsoawvYxbWc8";

  const { isLoading, isError, error, success, homePageData } = useSelector(
    (state) => state.homePage
  )

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomePageData());
  }, [dispatch]);


  return (
    <footer className="bg-footerColor flex flex-col">
      <div className="mx-2 py-6 max-xl:px-2 lg:py-5 flex-1">
        <div className="flex justify-evenly ">
          <div className="grid grid-cols-4 max-md:grid-cols-2 gap-20 sm:gap-6 max-lg:mt-4">
            <div>
              <h2 className="mb-4 text-3xl font-bold text-primary">Links</h2>
              <ul>
                <li className="mb-2 text-primary hover:text-footerSecondary">
                  <Link to="/" className="underline">
                    Home
                  </Link>
                </li>

                <li className="mb-2 text-primary hover:text-footerSecondary">
                  <Link to="/about" className="underline">
                    About us
                  </Link>
                </li>

                <li className="mb-2 text-primary hover:text-footerSecondary">
                  <Link to="/menu" className="underline">
                    Menu
                  </Link>
                </li>

                <li className="mb-2 text-primary hover:text-footerSecondary">
                  <Link to="/contact" className="underline">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            {
              success &&
              <div className="flex flex-col">
                <h2 className="mb-4 text-3xl font-bold text-primary">
                  Opening Hours
                </h2>
                {
                  homePageData[0].openingHours.map((openingHour, index) => {
                    return (
                      < div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                          <span>
                            <strong>{openingHour.day}</strong>
                          </span>
                          <span>{openingHour.time}</span>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            }

            < div className="flex flex-col gap-2">
              <h2 className="mb-4 text-3xl font-bold text-primary">
                Contact Us
              </h2>
              <a
                href={googleMapAddress}
                className="underline text-primary hover:cursor-pointer hover:text-footerSecondary"
                target="_blank"
                rel="noreferrer"
              >
                <span className="flex items-center gap-2 ">
                  <CiLocationOn size="35px" /> Parijsstraat 22, 3000 Leuven
                </span>
              </a>
              <span className="flex items-center gap-2 mb-4 hover:text-footerSecondary hover:cursor-pointer text-primary mt-2">
                <TfiEmail size="30px" />
                <a className="underline" href={`mailto:info@lalibela.be`}>
                  info@lalibela.be
                </a>
              </span>
              <div className="flex gap-2">
                <IoCallSharp size="30px" />
                <p>016 60 19 57</p>
              </div>
            </div>

            <div className="ml-24 max-md:ml-0 pr-4 overflow-hidden">
              <h2 className="mb-4 text-3xl font-bold text-primary">
                Follow Us
              </h2>
              <ul className="flex gap-4 items-center">
                <a
                  href="https://www.facebook.com/profile.php?id=61558652592202"
                  target="_blank"
                  rel="noreferrer"
                  onMouseEnter={() => setIsFbIconHovered(true)}
                  onMouseLeave={() => setIsFbIconHovered(false)}
                >
                  <FaFacebook
                    size="40px"
                    color={isFbIconHovered ? "#E5E4E2" : "#000000"}
                  />
                </a>

                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  onMouseEnter={() => setIsTripAdvirosIconHovered(true)}
                  onMouseLeave={() => setIsTripAdvirosIconHovered(false)}
                >
                  <BiLogoTripAdvisor
                    size="40px"
                    color={isLTripAdvisorIconHovered ? "#E5E4E2" : "#000000"}
                  />
                </a>

                <a
                  href="https://www.instagram.com/lalibela_restaurant_leuven/"
                  target="_blank"
                  rel="noreferrer"
                  onMouseEnter={() => setIsInstagramIconHovered(true)}
                  onMouseLeave={() => setIsInstagramIconHovered(false)}
                >
                  <FaInstagram
                    size="40px"
                    color={isInstagramIconHovered ? "#E5E4E2" : "#000000"}
                  />
                </a>
              </ul>
            </div>
          </div>
        </div>

        <hr className="my-4 border-gray-200 sm:mx-auto lg:my-8" />

        <div className="flex justify-center gap-1">
          <span className="text-sm text-primary sm:text-center">
            <Link to="/" className="hover:underline">
              LALIBELA{" "}
            </Link>
          </span>
          <span className="text-sm text-primary sm:text-center">
            - BTW - BE 1007643720
          </span>
        </div>
      </div>
    </footer >
  );
};
