import { motion } from "framer-motion"

// Children will the the item that we will show on top of the backdrop
// onClick allows to pass a custom event handler to handle what to do when backdrop is clicked
export const Backdrop = ({ children, onClick }) => {
    return (
        <motion.div
            className="fixed top-0 left-0 h-full w-full bg-[#000000]/70 z-50 flex justify-center items-center"
            onClick={onClick}
            initial={{ opacity: 0 }} //initially opacity is 0 so item is hidden
            animate={{ opacity: 1 }} //during animation item is visible
            exit={{ opacity: 0 }} //after animation exists item is not visible anymore again
        >
            {/* children is rendered on top of out backdrop div, in our case here it's a popup */}
            {children}
        </motion.div>
    )
}