import { Button } from "../components"
import { Link } from "react-router-dom"

export const PageNotFound = () => {
    return (
        <div className="flex flex-col justify-center items-center p-4 gap-4">
            <h3>SORRY, PAGE NOT FOUND!</h3>
            <Link to="/">
                <Button text="Back To Home" />
            </Link>
        </div>
    )
}