import { Helmet } from 'react-helmet-async'
import { Popup } from "../components/Model/Popup"
import { getGalleryPageData } from '../reducers/galleryPageSlice';
import { useDispatch, useSelector } from "react-redux";
import { PiSpinnerThin } from "react-icons/pi";
import { useEffect, useState } from 'react';

export const Gallery = () => {

    const { isLoading, isError, error, success, galleryPageData } = useSelector(
        (state) => state.galleryPage
    )

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGalleryPageData());
    }, [dispatch]);

    const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
    const handleClose = () => {
        setSelectedImageIndex(-1)
    }

    const nextImage = () => {
        var nextImageIndex = (selectedImageIndex + 1) % galleryPageData[0].images.length
        setSelectedImageIndex(nextImageIndex);
    }

    const prevImage = () => {
        setSelectedImageIndex((selectedImageIndex - 1 + galleryPageData[0].images.length) % galleryPageData[0].images.length);
    }


    return (
        <main className="flex justify-center items-center p-4">

            <Helmet>
                <title>Lalibela Leuven - Gallery</title>
                <meta name="keywords" content="Ethiopian cuisine, Ethiopian restaurant, Leuven, Authentic Ethiopian food, Ethiopian dishes, Cultural dining experience, Traditional Ethiopian recipes, Vegan, Vegetarian options, Ethiopian spices, Ethiopian coffee ceremony, Parijsstraat 22, 3000 Leuve" />
                <meta name="description" content="Discover Ethiopia at Leuven Ethiopian Restaurant. Explore our vibrant gallery of authentic dishes and cultural ambiance. Join us for a visual journey!" />
                <link rel="canonical" href="https://www.lalibela.be/gallery" />
            </Helmet>
            {
                isLoading ? (
                    <div className="flex justify-center items-center">
                        <PiSpinnerThin className="animate-spin" size="200px" />
                    </div>
                ) : isError ? (
                    <div className="flex justify-center flex-col items-center">
                        <p className="text-4xl mt-4">{error}</p>
                        <p className="text-4xl mt-4">Internet could be slow, please refresh the page again</p>
                    </div>
                ) : (
                    success &&
                    <div>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                            {
                                galleryPageData[0].images.map((image, index) => {
                                    return (
                                        <img className='rounded border-4 border-transparent hover:border-buttonPrimary hover:border-4 cursor-pointer'
                                            onClick={() => setSelectedImageIndex(index)}
                                            key={index} src={`https://www.api.lalibela.be${image.image}`} alt="" />
                                    )
                                })
                            }
                        </div>

                        {
                            selectedImageIndex !== -1 &&
                            <Popup handleClose={handleClose} displayContent={
                                <div className='mb-4 relative w-[1200px] 
                                max-[1281px]:w-[1100px]
                                max-[1157px]:w-[1000px] 
                                max-[1050px]:w-[900px]
                                max-[1000px]:w-[800px]
                                max-[950px]:w-[750px]
                                max-[900px]:w-[700px]
                                max-[800px]:w-[650px]
                                max-[750px]:w-[550px]
                                max-[700px]:w-[500px]
                                max-[650px]:w-[400px]
                                max-[600px]:w-[350px]
                                items-center 
                                flex 
                                justify-center'>
                                    <img src={`https://www.api.lalibela.be${galleryPageData[0].images[selectedImageIndex].image}`} alt="" />

                                    <button type="button" onClick={() => prevImage()} className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-5 cursor-pointer group focus:outline-none" data-carousel-prev>
                                        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                            <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                            </svg>
                                            <span className="sr-only">Previous</span>
                                        </span>
                                    </button>
                                    <button type="button" onClick={() => nextImage()} className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-5 cursor-pointer group focus:outline-none" data-carousel-next>
                                        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                            <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                            </svg>
                                            <span className="sr-only">Next</span>
                                        </span>
                                    </button>
                                </div>
                            } />
                        }
                    </div>
                )
            }
        </main>
    )
}