import { motion } from "framer-motion"

export const Button = ({ text, onClick }) => {
    return (
        <div className="flex justify-center">
            <motion.button type="submit" onClick={onClick} className="text-gray-900 focus:outline-none text-xl max-sm:text-xl max-sm:px-5 bg-buttonPrimary/90 rounded-lg hover:bg-buttonSecondary/90 text-white font-medium rounded-lg px-5 py-3 mb-2"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}>
                {text}
            </motion.button>
        </div>
    )
}