import { motion } from "framer-motion"
import { Backdrop } from "../Backdrop/Backdrop"
import { Button } from "../Button";

// Holds possible animation states
const dropIn = {
    hidden: {
        // when the popup is hidden we will translate it along y axis by -100% of the view height
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 80,
            stiffness: 500
        }
    },
    exit: {
        // when the popup exits, it translates to positive 100 view height
        y: "100vh",
        opacity: 0
    }
};

//handleClose will handle close as it names
//text is what we want to show on the model
export const Popup = ({ handleClose, displayContent }) => {
    return (
        //on backdrop clicked, it will auto close the popup
        <Backdrop onClick={() => { handleClose() }}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="bg-[#bd7969] rounded p-4 flex flex-col justify-center items-center"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                {displayContent}
                <Button onClick={() => { handleClose() }} text="Close" />
            </motion.div>
        </Backdrop >
    )
}