import { useState } from "react"
import { Button } from "./Button"
import axios from "axios"
import { PiSpinnerThin } from "react-icons/pi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useFormik } from "formik";
import { EmailFormValidation } from "../validations/EmailFormValidation";

const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: ""
};

export const EmailForm = () => {

    const [emailSent, setEmailSent] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: initialValues,
        validationSchema: EmailFormValidation,
        onSubmit: (values, {resetForm}) => {
            setShowSpinner(true)    
            axios.post('https://www.api.lalibela.be/api/sendEmail/', values)
            .then(response => {
                console.log(response)
                setEmailSent(true)
                setShowSpinner(false)
                resetForm();
            })
            .catch(error => {
                console.warn(error)
                setShowSpinner(false)
            })
        },
    })
    
    return (
        <div className="flex justify-center items-center">
            { showSpinner &&
                <PiSpinnerThin className="animate-spin" size="200px"/>
            }
        {
        !emailSent && !showSpinner &&
        <form className="w-full mx-auto" onSubmit={handleSubmit}>
            <div>
                <div className="flex flex-col justify-center items-center gap-4 mt-10 mb-4">
                    <div>
                        {errors.name && touched.name ? <p className="text-rose-600 text-sm">{errors.name}</p> : null}
                        <input value={values.name} onChange={handleChange} onBlur={handleBlur} autoComplete="off" type="text" name="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[800px] h-14 p-2.5" placeholder="Name(Required)" />
                    </div>
                    <div>
                        {errors.email && touched.email ? <p className="text-rose-600 text-sm">{errors.email}</p> : null}
                        <input value={values.email} onChange={handleChange} onBlur={handleBlur} autoComplete="off" type="text" name="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[800px] h-14 p-2.5" placeholder="Email(Required)" />
                    </div>
                    <input value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} autoComplete="off" type="text" name="phoneNumber"  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[800px] h-14 p-2.5" placeholder="Phone number" />
                    <div>
                        {errors.subject && touched.subject ? <p className="text-rose-600 text-sm">{errors.subject}</p> : null}
                        <input value={values.subject} onChange={handleChange} onBlur={handleBlur} autoComplete="off" type="text" name="subject" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[800px] h-14 p-2.5" placeholder="Subject(Required)" />
                    </div>
                    <div>
                        {errors.message && touched.message ? <p className="text-rose-600 text-sm">{errors.message}</p> : null} 
                        <textarea value={values.message} onChange={handleChange} onBlur={handleBlur} autoComplete="off" id="message" name="message" rows="10" className="block p-2.5 lg:w-[800px] min-h-72 max-h-72 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Message(Required)"></textarea>
                    </div>
                    <Button text="Send" />
                </div>
            </div>
        </form >
        }
        
        {
            emailSent &&
            <>
                <div className="flex flex-col gap-2">
                    <IoMdCheckmarkCircleOutline className="text-green-500 ml-4" size="150px"/>
                    <span>Email sent succesfully!</span>
                    <Button text="New email" onClick={() => setEmailSent(false)}/>
                </div>
            </>
        }
        </div>
    )
}