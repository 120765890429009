import { LuVegan } from "react-icons/lu";
import VegetarianImage from "../../assets/images/vegetarianIcon.svg"

export const ListMenuItem = ({ item }) => {
    const { title, price, vegan, vegetarian, descriptionEnglish, descriptionDutch } = item;
    return (
        <article className="flex max-sm:flex-col max-sm:p-2 max-sm:hover:border-secondary max-sm:rounded justify-start items-center gap-4">
            {
                //<img className="w-[300px] h-[200px] rounded border-2 hover:sm:border-secondary" style={{flexShrink: 0, objectFit: 'cover' }} src={img} alt={title}/>
            }
            <div className="flex flex-col gap-4 w-full p-4">
                <div className="flex justify-between  gap-2">
                    <div className="flex gap-2">
                        <span className="font-bold ">{title}</span>
                        {
                            vegan &&
                            <LuVegan className="text-green-500" size="25px" />
                        }
                        {
                            !vegan && vegetarian &&
                            <img className="w-12" src={VegetarianImage} alt="veg" />
                        }
                    </div>
                    <span>€{price}</span>
                </div>
                <hr />
                <p className="text-sm ">{descriptionEnglish}</p>
                <p className="text-sm text-[#C5602B] ">{descriptionDutch}</p>
            </div>
        </article>
    )
}