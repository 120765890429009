import { Routes, Route } from "react-router-dom"
import { Home, About, Menu, Chefs, Gallery, Contact, PageNotFound } from "../pages"

export const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/chefs" element={<Chefs />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={< PageNotFound />} />
            </Routes >
        </>
    )
}
